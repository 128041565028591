import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { string, func } from 'prop-types';
import { useQuery, gql } from '@apollo/client';

import { Select } from './Select/Select.js';

const SEARCH_CLIENTS_QUERY = gql`
  query SEARCH_CLIENTS_QUERY($input: CollectionSearchParams!) {
    searchAssets(input: $input) {
      hits {
        _id
        displayName
        client {
          _id
          configs {
            projectPriorities {
              id
              name
            }
          }
        }
      }
    }
  }
`;

// getProjectPrioritySelectOptions :: { searchClients: { hits: [Client] } } -> [SelectOption]
const getProjectPrioritySelectOptions = R.compose(
  R.map(
    R.applySpec({
      value: R.prop('id'),
      label: R.prop('name'),
    }),
  ),
  R.pathOr(
    [],
    ['searchAssets', 'hits', '0', 'client', 'configs', 'projectPriorities'],
  ),
);

export function ProjectPrioritySelect({ value, onChange }) {
  const clientId = useSelector((state) => state.globalClient?.clientId);

  const { data } = useQuery(SEARCH_CLIENTS_QUERY, {
    variables: {
      input: { size: 1, query: !clientId ? null : { term: { clientId } } },
    },
  });

  const selectProps = {
    isClearable: true,
    value,
    onChange,
    placeholder: 'Select Priority',
    options: getProjectPrioritySelectOptions(data),
  };

  return <Select {...selectProps} />;
}

ProjectPrioritySelect.propTypes = {
  value: string,
  onChange: func.isRequired,
};
