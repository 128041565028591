import React from 'react';
import styled from 'styled-components';
import { Field, FormSpy } from 'react-final-form';
import { bool, func, object, string, arrayOf, oneOfType } from 'prop-types';
import { validateField } from '@poly/admin-book';

const FormFieldWithLabelWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;

  > div:not(:first-child) {
    margin-bottom: 0;
  }
`;

const FormFieldLabelS = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
  color: #999;
`;

const FormFieldErrorS = styled.div`
  font-size: 10px;
  line-height: 12px;
  margin-top: 8px;
  color: #e75858;
`;

function FormFieldWithLabelComp({
  label,
  required,
  Component,
  validators,
  formSpyProps,
  componentProps = {},
  ...fieldProps
}) {
  return (
    <FormFieldWithLabelWrapperS>
      {!!label && (
        <FormFieldLabelS>{required ? `${label}*` : label}</FormFieldLabelS>
      )}
      <Field {...fieldProps} validate={validateField(validators)}>
        {({ input, meta }) => {
          const hasError =
            !!meta.touched && (!!meta.error || !!meta.submitError);

          return (
            <>
              <Component
                {...input}
                meta={meta}
                required={required}
                {...componentProps}
                hasError={hasError}
                error={meta.touched && meta.error ? meta.error : ''}
                {...(formSpyProps ? { formSpyProps } : {})}
              />
              {hasError && (
                <FormFieldErrorS>
                  {meta.error || meta.submitError}
                </FormFieldErrorS>
              )}
            </>
          );
        }}
      </Field>
    </FormFieldWithLabelWrapperS>
  );
}

FormFieldWithLabelComp.propTypes = {
  label: string,
  required: bool,
  withFormSpy: bool,
  Component: oneOfType([func, object]).isRequired,
  validators: arrayOf(arrayOf(oneOfType([string, func]))),
  // eslint-disable-next-line react/forbid-prop-types
  componentProps: object,
  // eslint-disable-next-line react/forbid-prop-types
  formSpyProps: object,
};

export function FormFieldWithLabel(props) {
  return <FormFieldWithLabelComp {...props} />;
}

export function SpyFormFieldWithLabel(props) {
  return (
    <FormSpy subscription={{ values: true }}>
      {(formSpyProps) => (
        <FormFieldWithLabelComp {...props} formSpyProps={formSpyProps} />
      )}
    </FormSpy>
  );
}
