import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { insertParamsIntoURL, insertQueryParamsIntoURL } from '@poly/utils';
import { useNavigate, useParams } from '@poly/client-routing';
import { WorkOrderPriority } from '@poly/constants';
import { getThemeColor } from '@poly/admin-book';
import { useUploadAttachment } from '@poly/client-utils/src/files/useUploadAttachment.js';
import {
  CLIENT_MARKUP_RULES_ERROR,
  ASSET_PROJECT_ALREADY_EXIST_ERROR,
} from '@poly/constants/src/error-messages.js';

import { routes } from '../routes.js';
import { Icon } from '../icons/Icon.js';
import { Button } from '../components/Button.js';
import { CONTACT_MOBILE } from '../constants.js';
import { TextArea } from '../components/TextArea.js';
import { Selector } from '../components/Selector.js';
import { DropZone } from '../components/DropZone.js';
import { ContactPhoneLink, PhoneLink } from '../components/PhoneLink.js';
import { FlexColumnContainer } from '../components/Containers.js';
import { ErrorText, SubTitleText, Text } from '../components/Text.js';
import { AssetDetailsHeaderWithLogo } from '../components/AssetDetailsHeaderWithLogo.js';
import { useAssetDetailsByQrCode } from '../hooks/useAssetDetails.js';

const SubmitRequestForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 19px;
`;

const ContentContainer = styled(FlexColumnContainer)`
  padding: 26px;
  height: 100%;
  border-top: 2px solid;
  border-color: ${getThemeColor(['midLight'])};
`;

const DetailsInput = styled(TextArea)`
  margin-bottom: 19px;
`;

const InputLabel = styled(Text)`
  margin-bottom: 15px;
  color: ${getThemeColor(['primaryLight'])};
`;

// getPriorityOptions :: { client: Client } -> [PriorityOption]
// PriorityOption = {
//    id: String
//    title: String
//    subTitle: String
// }
const getPriorityOptions = R.compose(
  R.map(
    R.applySpec({
      id: R.prop('id'),
      title: R.prop('name'),
      subTitle: R.compose(
        R.join(' '),
        R.juxt([R.prop('amount'), R.prop('unit')]),
      ),
    }),
  ),
  R.pathOr([], ['client', 'configs', 'projectPriorities']),
);

export const createAssetProjectMutation = gql`
  mutation createAssetProject($input: CreateAssetProjectInput!) {
    createAssetProject(input: $input) {
      project {
        projectId
        _id
      }
    }
  }
`;

const EmergencyMessageContainer = styled.div`
  height: 75%;
  text-align: center;
`;

function EmergencyMessage() {
  return (
    <EmergencyMessageContainer>
      <Icon name="phone" width="50" height="50" />
      <p>For Emergency service please call</p>
      <PhoneLink phone={CONTACT_MOBILE} />
    </EmergencyMessageContainer>
  );
}

const createProjectFormId = 'createProjectFormId';

function ClientMarkupRulesError() {
  return (
    <>
      An error occurred while creating the request. Please contact support for
      assistance&nbsp;
      <ContactPhoneLink size={14} withIcon={false} color="secondaryDark" />
    </>
  );
}

export function SubmitProjectScreen() {
  const [error, setError] = useState();
  const [upload, setUpload] = useState();
  const [details, setDetails] = useState('');
  const [priorityId, setPriorityId] = useState(WorkOrderPriority.NORMAL);
  const [createProject, data] = useMutation(createAssetProjectMutation);

  const { assetQrCodeId, projectDescription } = useParams();
  const { asset } = useAssetDetailsByQrCode(assetQrCodeId);

  const uploadFile = useUploadAttachment();

  const navigate = useNavigate();

  const onChangeDetails = (e) => {
    const { value } = e.target;
    setDetails(value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!asset._id) {
      return null;
    }

    if (priorityId === WorkOrderPriority.EMERGENCY) {
      return navigate(routes.emergency);
    }

    try {
      let uploadedFileId = null;

      if (upload) {
        uploadedFileId = await uploadFile(upload);
      }

      const invoiceDescriptionValue = details
        ? `${projectDescription}. ${details}`
        : projectDescription;

      const input = {
        priorityId,
        assetId: asset._id,
        description: projectDescription,
        invoiceDescription: invoiceDescriptionValue,
        ...(uploadedFileId ? { uploadedFileId } : {}),
      };
      const res = await createProject({ variables: { input } });
      const projectId = R.path(
        ['data', 'createAssetProject', 'project', 'projectId'],
        res,
      );
      const url = insertParamsIntoURL({ assetQrCodeId }, routes.projectCreated);
      const urlWithQuery = insertQueryParamsIntoURL({ projectId }, url);
      return navigate(urlWithQuery);
    } catch (err) {
      if (err.message === ASSET_PROJECT_ALREADY_EXIST_ERROR) {
        const existingProjectUrl = insertParamsIntoURL(
          { assetQrCodeId },
          routes.existingProject,
        );

        return navigate(existingProjectUrl);
      }

      if (err.message === CLIENT_MARKUP_RULES_ERROR) {
        return setError(ClientMarkupRulesError);
      }

      return setError(err.message);
    }
  };
  const isEmergency = priorityId === WorkOrderPriority.EMERGENCY;

  return (
    <>
      <AssetDetailsHeaderWithLogo asset={asset} hideAssetImage />
      <ContentContainer>
        <SubmitRequestForm id={createProjectFormId}>
          <FlexColumnContainer>
            <SubTitleText>{projectDescription}</SubTitleText>
            <Selector
              value={priorityId}
              onClick={setPriorityId}
              label="Priority *"
              optionWidth="calc(33.33% - 7px)"
              optionHeight="52px"
              options={getPriorityOptions(asset)}
              justify="flex-start"
              gap="10px"
            />
            {!isEmergency && (
              <>
                <InputLabel>Any other details you’d like to add?</InputLabel>
                <DetailsInput
                  onChange={onChangeDetails}
                  value={details}
                  name=""
                />
                <DropZone value={upload} onChange={setUpload} />
              </>
            )}
          </FlexColumnContainer>
        </SubmitRequestForm>
        {error && <ErrorText>{error}</ErrorText>}
        {isEmergency ? (
          <EmergencyMessage />
        ) : (
          <Button
            loading={data.loading}
            type="submit"
            form={createProjectFormId}
            width="100%"
            onClick={onSubmit}
            caption="Submit Request"
          />
        )}
      </ContentContainer>
    </>
  );
}
