import * as R from 'ramda';
import React from 'react';
import { shape, string } from 'prop-types';
import { NOTHING_UI_STRING, WorkOrderPriority } from '@poly/constants';

import { Icon } from '../icons/Icon.js';
import { ColorTextS, StatusContainerS } from './ProjectStatus.js';

const getDefaultProjectPriorityConfig = ({ name }) => ({
  color: '#12347A',
  background: '#f0f4fc',
  text: name || NOTHING_UI_STRING,
  icon: 'wrench',
});

const projectPriorityConfig = {
  [WorkOrderPriority.NORMAL]: {
    color: '#5C9819',
    background: '#d8eac3',
    text: 'Normal',
    icon: 'clock',
  },
  [WorkOrderPriority.URGENT]: {
    color: '#D19C19',
    background: '#FFEEC4',
    text: 'Urgent',
    icon: 'clock',
  },
  [WorkOrderPriority.EMERGENCY]: {
    color: '#E75858',
    background: '#FFEEEA',
    text: 'Emergency',
    icon: 'clock',
  },
  [WorkOrderPriority.PLANNED]: {
    color: '#8599F0',
    background: '#edf0fd',
    text: 'Planned',
    icon: 'clockHistory',
  },
};

// getProjectPriorityConfig :: { id: String, name: String } -> Object
const getProjectPriorityConfig = R.either(
  R.compose(R.prop(R.__, projectPriorityConfig), R.prop('id')),
  getDefaultProjectPriorityConfig,
);

export function ProjectPriority({ priority }) {
  const config = getProjectPriorityConfig(priority);

  if (!config) {
    return null;
  }

  const { color, text, background, icon } = config;

  return (
    <StatusContainerS background={background}>
      <Icon name={icon} color={color} />
      <ColorTextS color={color}>{text}</ColorTextS>
    </StatusContainerS>
  );
}

ProjectPriority.propTypes = {
  priority: shape({ id: string.isRequired, name: string.isRequired }),
};
