import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useTableInfiniteScrollQuery } from '@poly/client-utils';
import {
  RecurringProjectTypes,
  ELASTIC_SCORE_FIELD,
  DESC_SORT_ORDER,
  WorkOrderStatus,
  ProjectType,
} from '@poly/constants';

import { useStateInputForInfiniteListQuery } from './useStateInputForInfiniteListQuery.js';

const SEARCH_PROJECTS = gql`
  query SEARCH_PROJECTS($input: CollectionSearchParams!) {
    searchProjects(input: $input) {
      hits {
        _id
        status
        projectId
        startDate
        description
        priority {
          id
          name
        }
        manager {
          fullName
        }
      }
      total
    }
  }
`;

const defaultSort = [{ createdAt: DESC_SORT_ORDER }, ELASTIC_SCORE_FIELD];

export const useSearchProjects = (
  query,
  searchTerm = '',
  sort = defaultSort,
) => {
  const input = useStateInputForInfiniteListQuery(sort, searchTerm, {
    bool: {
      must_not: { match: { status: WorkOrderStatus.BLOCKED } },
      should: [
        {
          bool: {
            ...(query || {}),
            filter: {
              term: {
                'parent.type':
                  RecurringProjectTypes.preventiveMaintenanceProject,
              },
            },
          },
        },
        {
          bool: {
            ...(query || {}),
            filter: {
              terms: {
                type: [ProjectType.PASS_THROUGH, ProjectType.WORK_ORDER],
              },
            },
          },
        },
      ],
    },
  });

  const {
    data,
    loading,
    tableProps: listProps,
  } = useTableInfiniteScrollQuery(SEARCH_PROJECTS, input, {
    endpointName: 'searchProjects',
    pageSize: 25,
  });

  const projects = R.pathOr([], ['searchProjects', 'hits'], data);

  const total = R.pathOr(0, ['searchProjects', 'total'], data);

  return { listProps, projects, loading, total };
};
